/* === POLYFILLS === */

import "core-js/stable";
import "regenerator-runtime/runtime";

/* -- fetch -- */
import 'promise/polyfill';
import 'whatwg-fetch';

/* -- forms -- */
import 'formdata-polyfill';

/* -- classList polyfill, since IE11 doesn't fully support all methods -- */
import 'classlist-polyfill';

/* -- URLSearchParams -- */
import 'url-search-params-polyfill';

/* -- IntersectionObserver -- */
import 'intersection-observer';

/* -- Proxy -- */
import 'proxy-polyfill';

/* -- URL constructor -- */
import 'url-polyfill';

/* -- allow use of `forEach` on `NodeList` --*/
import 'nodelist-foreach-polyfill';

/* -- polyfill for `window.scroll` with options --*/
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

/* -- polyfill for `Element.scroll` -- */
import 'scroll-behavior-polyfill';

window.global = window;

/* -- simple CustomEvent polyfill -- */
(function() {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;

  /// HACK: IE11 and below will return `'object'` here, whereas newer browsers
  /// will return `'function'`. This effectively allows us to sidestep
  /// `document.createEvent`.
  if (typeof window.Event === 'object') {
    window.Event = CustomEvent;
  }
})();
